



























import parseISO from 'date-fns/parseISO';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
const getTime = (dateString: string) => {
    const startTimeDate = parseISO(dateString);
    const hours = `${getHours(startTimeDate) < 10 ? '0' : ''}${getHours(startTimeDate)}`;
    const minutes = `${getMinutes(startTimeDate) < 10 ? '0' : ''}${getMinutes(startTimeDate)}`;
    return `${hours}:${minutes}`;
};

const getDate = (dateString: string, twoLined: boolean) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const startTimeDate = parseISO(dateString);
    const month = months[startTimeDate.getUTCMonth()];
    return `${startTimeDate.getUTCDate()} ${month.slice(0, 3)}`;
};

export default {
    name: "EventCard",
    props: ['event', 'multiRow'],
    methods: {
        getTime: getTime,
        getDate: getDate
    }
}
