











import EventCard from './EventCard.vue';
const getTime = (dateString: string) => {
    const startTimeDate = new Date(dateString);
    const hours = `${startTimeDate.getHours() < 10 ? '0' : ''}${startTimeDate.getHours()}`;
    const minutes = `${startTimeDate.getMinutes() < 10 ? '0' : ''}${startTimeDate.getMinutes()}`;
    return `${hours}:${minutes}`;
};

const getDate = (dateString: string) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const startTimeDate = new Date(dateString);
    const month = months[startTimeDate.getMonth()];
    return `${startTimeDate.getDate()} ${month}`;
};

export default {
    name: "EventsList",
    props: ['events'],
    methods: {
        getTime: getTime,
        getDate: getDate
    },
    components: {
        EventCard
    }
}
