





























import "babel-polyfill";
import Vue from "vue";
import axios from "axios";
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import getTime from 'date-fns/getTime';
import EventsList from './components/EventsList.vue';
const allEggsGroupID: string = '226774494441847';
const osloGroupID: string = '307618086300936';
const trondheimGroupID: string = '169696236847563';
const stavangerGroupID: string = '1408965695782516';
const copenhagenGroupID: string = '1956039421305499';
let groupId: string = '307618086300936';

type EventType = {
  id: string,
  place: {
    name: string,
    location: {
      city: string
    }
  },
  start_time: string,
  description: string,
  showToAll: boolean,
  end_time?: string,
};

const filterOnCity = (events: EventType[], city: string) => {
  const localEvents: EventType[] = [];
  for (let event of events) {
    /*
    if (event.place && event.place.name &&
            event.place.name.toLowerCase().includes(city.toLowerCase()) ||
            (event.place && event.place.location &&
            event.place.location.city &&
            event.place.location.city.toLowerCase().includes(city.toLowerCase()))) {
    }
    */
    let eventToPush = {...event};
    if (city === 'ALL_EGGS') {
      eventToPush = {
        ...event,
        showToAll: true
      };
    }
    const nowDate = new Date();
    if ((event.end_time && isBefore(nowDate, parseISO(event.end_time)))|| isBefore(nowDate, parseISO(event.start_time))) {
      if (event.description) {
        if (event.description.indexOf('HIDE_FROM_SCREEN') === -1) {
          localEvents.push(eventToPush);
        }
      } else {
        localEvents.push(eventToPush);
      }
    }
  }
  return localEvents;
};

async function getEventFeed (city: string) {
  let events: EventType[] = [];
  await axios.get(`https://api.eggsdesign.com/events/${allEggsGroupID}`).then((response) => {
    if (response && response.data) {
      events = events.concat(filterOnCity(response.data.data, 'ALL_EGGS'));
    }
  }).catch((e) => console.log(e.message));

  await axios.get(`https://api.eggsdesign.com/events/${groupId}`).then((response) => {
    if (response && response.data) {
      console.log(response.data.data);
      events = events.concat(filterOnCity(response.data.data, city));
    }
  }).catch((e) => console.log(e.message));
  return events.sort((a, b) => getTime(parseISO(a.start_time)) - getTime(parseISO(b.start_time)));
}

export default Vue.extend({
  data: function () {
    return {
      isLoading: true,
      events: [],
      city: 'oslo'
    }
  },
  methods: {
    setCityGroupId: (city: string) => {
      if (city.toLowerCase() === 'oslo') {
        groupId = osloGroupID;
      } else if (city.toLowerCase() === 'trondheim') {
        groupId = trondheimGroupID;
      } else if (city.toLowerCase() === 'stavanger') {
        groupId = stavangerGroupID;
      } else if (city.toLowerCase() === 'copenhagen') {
        groupId = copenhagenGroupID;
      }
    },
    handleCityChange: function (e: { target: { value: string } }) {
      this.setCityGroupId(e.target.value);
      this.isLoading = true;
      if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('habitat', e.target.value);
        window.location.search = searchParams.toString();
      }
      getEventFeed(e.target.value).then(cityEvents => {
        this.events = cityEvents;
        this.isLoading = false;
      });
    }
  },
  components: {
    EventsList
  },
  mounted() {
    let uri = window.location.href.split('?');
    if (uri.length == 2)
    {
      let vars = uri[1].split('&');
      let getVars = {
        habitat: ''
      };
      let tmp = [];
      for (const v of vars) {
        tmp = v.split('=');
        if (tmp.length == 2) {
          getVars[tmp[0]] = tmp[1];
        }
      }
      if (getVars.habitat.length > 0) {
        this.city = getVars.habitat.toLowerCase();
        this.setCityGroupId(getVars.habitat.toLowerCase());
      }
    }
    getEventFeed(this.city).then(cityEvents => {
      this.events = cityEvents;
      console.log(cityEvents);
      this.isLoading = false;
    });
  }
});
